/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
import SelectablePreview from '../../../../components/SelectablePreview';
// ASSETS
import ContentSectionDefaultImage from '../../../../assets/img/content-section_default.svg';
import ContentSectionReversedImage from '../../../../assets/img/content-section_reversed.svg';
import ContentSectionFulWidthImage from '../../../../assets/img/content-section_full-width.svg';
import ContentSectionFulWidthPaddedImage from '../../../../assets/img/content-section_full-width-padded.svg';
import ContentSectionTextOnlyImage from '../../../../assets/img/content-section_text-only.svg';
import ContentSectionFloatingCardImage from '../../../../assets/img/content-section_floating-card.svg';
import DynamicBannerSectionImage from '../../../../assets/img/banner-section_dynamic.svg';
import CustomSectionImage from '../../../../assets/img/content-section_custom.svg';
// SERVICES AND HELPERS
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import ContentSectionValidator from '../../../../helpers/validators/cms/contentSection/ContentSectionValidator';
import * as contentSectionService from '../../../../services/cms/contentSectionService';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

Quill.register('modules/imageResize', ImageResize);

const initialContentSectionModel = {
  id: 0,
  pageId: 0,
  bannerId: 0,
  name: '',
  rawHTML: '',
  type: constants.CONTENT_SECTION.ContentSectionTypeDefault,
  isActive: true,
  title: '',
  description: '',
  alignment: 'LEFT',
  imagePath: '',
  image: null,
  isPadded: false,
  isFloatingCard: false,
  hasRoundedCorners: false,
  isTextOnly: false,
  primaryButtonText: '',
  primarButtonLink: '',
  primaryButtonActionType: null,
  secondaryButtonText: '',
  secondaryButtonLink: '',
  secondaryButtonActionType: null,
};

const format = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'width',
  'height',
  'style',
  'align',
];

const linkTypes = [
  { value: constants.CONTENT_SECTION.ButtonActionTypePage, label: 'Page' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeLink, label: 'Link (New Tab)' },
  { value: constants.CONTENT_SECTION.ButtonActionTypePhoneNumber, label: 'Phone Number' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeEmail, label: 'Email Address' },
];

const alignmentOptions = [
  { value: constants.CONTENT_SECTION.LeftAlignment, label: 'Left (Default)' },
  { value: constants.CONTENT_SECTION.CenterAlignment, label: 'Center' },
  { value: constants.CONTENT_SECTION.RightAlignment, label: 'Right' },
];

const components = [
  {
    id: 0,
    title: 'Custom',
    image: CustomSectionImage,
    type: constants.CONTENT_SECTION.ContentSectionCustom
  },
  {
    id: 1,
    title: 'Content Section (Default)',
    image: ContentSectionDefaultImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeDefault
  },
  {
    id: 2,
    title: 'Content Section (Reversed)',
    image: ContentSectionReversedImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeReversed
  },
  {
    id: 3,
    title: 'Content Section Full Width',
    image: ContentSectionFulWidthImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeFullWidth
  },
  {
    id: 4,
    title: 'Content Section Full Width (Padded)',
    image: ContentSectionFulWidthPaddedImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded
  },
  {
    id: 5,
    title: 'Content Section (Text Only)',
    image: ContentSectionTextOnlyImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeTextOnly
  },
  {
    id: 6,
    title: 'Content Card',
    image: ContentSectionFloatingCardImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeCard
  },
  {
    id: 7,
    title: 'Content Banner',
    image: DynamicBannerSectionImage,
    type: constants.CONTENT_SECTION.ContentSectionTypeBanner
  },
];

const ContentSectionManagementPage = (props) => {
  const { showAlert, history } = props;
  const { contentSectionId, pageId, blogId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const quillContainerRef = useRef(null);
  const quillRef = useRef(null);
  const [contentSectionModel, setContentSectionModel] = useState({
    ...initialContentSectionModel,
    pageId: pageId || 0,
    blogId: blogId || 0
  });
  const [alignment, setAlignment] = useState([{ label: 'Left (Default)', value: 'LEFT' }]);
  const [primaryActionType, setPrimaryActionType] = useState(null);
  const [secondaryActionType, setSecondaryActionType] = useState(null);
  const [file, setFile] = useState([]);
  const [modules, setModules] = useState({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: {} // INITIALLY EMPTY, TO BE UPDATED VIA USE EFFECT
    },
    clipboard: { matchVisual: false },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
  });

  useEffect(() => {
    let initialTop = 0;
    let quillWidth = 0;

    // FUNCTION TO APPLY STYLES BASED ON SCROLL POSITION
    const applyStyles = (isFixed) => {
      const toolbar = quillContainerRef.current.querySelector('.ql-toolbar');
      toolbar.style.position = isFixed ? 'fixed' : '';
      toolbar.style.top = isFixed ? '0' : '';
      toolbar.style.width = isFixed ? '100%' : '';
      toolbar.style.marginTop = isFixed ? '5px' : '';
      toolbar.style.zIndex = isFixed ? '1000' : '';
      toolbar.style.backgroundColor = '#FFFFFF';
      toolbar.style.boxShadow = isFixed ? '#66666694 0px 4px 6px' : 'none';
      toolbar.style.borderRadius = isFixed ? '6px' : '6px 6px 0 0';
      toolbar.style.transform = isFixed ? 'scale(1.05)' : '';
      toolbar.style.maxWidth = `${quillWidth}px`;
    };

    const handleScroll = () => {
      if (quillContainerRef.current) {
        const toolbar = quillContainerRef.current.querySelector('.ql-toolbar');
        const quill = quillContainerRef.current.querySelector('.quill');

        // SET INITIAL VALUES IF NOT ALREADY SET
        if (initialTop === 0 || quillWidth === 0) {
          initialTop = toolbar.offsetTop;
          quillWidth = quill.offsetWidth;

          // IMMEDIATELY APPLY STYLES BASED ON CURRENT SCROLL POSITION
          applyStyles(window.scrollY >= initialTop);
        } else {
          // APPLY STYLES BASED ON SCROLL POSITION
          applyStyles(window.scrollY >= initialTop);
        }
      }
    };

    // IMMEDIATELY CHECK SCROLL POSITION AND APPLY STYLES
    handleScroll();

    // LISTEN FOR SCROLL EVENTS
    window.addEventListener('scroll', handleScroll);

    // CLEAN UP ON COMPONENT UNMOUNT
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // [+ SVG SUPPORT] HANDLES THE IMAGE UPLOAD PROCESS FOR THE QUILL EDITOR
    const imageHandler = () => {
      // CREATE AN INPUT ELEMENT TO ACCEPT IMAGE FILES
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/svg+xml, image/png, image/jpeg');
      input.click(); // TRIGGER THE FILE INPUT DIALOG

      // LISTEN FOR FILE SELECTION
      input.onchange = async () => {
        const file = input.files[0]; // GET THE SELECTED FILE
        if (file) {
          // READ THE FILE AS A DATA URL FOR EMBEDDING
          const reader = new FileReader();
          reader.onload = (e) => {
            const quillEditor = quillRef.current.getEditor();
            const range = quillEditor.getSelection();
            const index = range ? range.index : quillEditor.getLength();
            // INSERT THE IMAGE AT THE CURRENT SELECTION OR AT THE END OF THE EDITOR CONTENT
            quillEditor.insertEmbed(index, 'image', e.target.result);
          };
          reader.readAsDataURL(file);
        }
      };
    };

    // UPDATE MODULES STATE
    setModules((prevModules) => ({
      ...prevModules,
      toolbar: {
        ...prevModules.toolbar,
        handlers: {
          ...prevModules.toolbar.handlers,
          image: imageHandler
        },
      },
    }));
  }, []);

  useEffect(() => {
    if (contentSectionId > 0) {
      getContentSection();
    }
  }, []);

  const formik = useFormik({
    initialValues: contentSectionModel,
    validationSchema: ContentSectionValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (contentSectionId) {
        updateContentSection(values);
      } else {
        createContentSection(values);
      }
    },
  });

  const getContentSection = async () => {
    setIsLoading(true);

    contentSectionService.getContentSectionById(contentSectionId).then((res) => {
      setContentSectionModel(res);
      fillSelectedOptions(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fillSelectedOptions = (res) => {
    let selectedAlignment = alignmentOptions.find((x) => x.value === res.alignment);
    let selectedPrimaryActionType = linkTypes.find((x) => x.value === res.primaryButtonActionType);
    let selectedSecondaryActionType = linkTypes.find((x) => x.value === res.secondaryButtonActionType);

    if (selectedAlignment) {
      setAlignment(selectedAlignment);
    }

    if (selectedPrimaryActionType) {
      setPrimaryActionType(selectedPrimaryActionType);
    }

    if (selectedSecondaryActionType) {
      setSecondaryActionType(selectedSecondaryActionType);
    }
  };

  const createContentSection = (model) => {
    if (file.length === 0 && ![constants.CONTENT_SECTION.ContentSectionTypeTextOnly, constants.CONTENT_SECTION.ContentSectionCustom].includes(formik.values.type)) {
      showAlert({ text: 'Error! Please upload an image', state: 'warning' });
      return;
    }

    setIsLoading(true);

    contentSectionService.createContentSection(helper.convertJsonToFormData(
      {
        ...model,
        image: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      if (model.blogId > 0) {
        history.push(`/cms/management/blog/edit/${model.blogId}`);
      } else if (model.pageId > 0) {
        history.push(`/cms/management/page/edit/${model.pageId}`);
      } else {
        history.push(`/cms/management/content-section/edit/${res.id}`);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateContentSection = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, image: file[0].file };
    }
    contentSectionService.updateContentSection(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/content-sections');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const showOrHideFields = (type) => {
    let floatingCardProp = false;
    let isTextOnlyProp = false;
    let isPaddedProp = false;
    let alignmentProp = 'LEFT';

    switch (type) {
      case constants.CONTENT_SECTION.ContentSectionTypeDefault:
        setAlignment({ label: 'Left (Default)', value: 'LEFT' });
        break;
      case constants.CONTENT_SECTION.ContentSectionTypeReversed:
        setAlignment({ label: 'Right', value: 'RIGHT' });
        alignmentProp = 'RIGHT';
        break;
      case constants.CONTENT_SECTION.ContentSectionTypeFullWidth:
      case constants.CONTENT_SECTION.ContentSectionTypeBanner:
        break;
      case constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded:
        isPaddedProp = true;
        break;
      case constants.CONTENT_SECTION.ContentSectionTypeTextOnly:
        isTextOnlyProp = true;
        break;
      case constants.CONTENT_SECTION.ContentSectionTypeCard:
        floatingCardProp = true;
        break;
      default:
        break;
    }

    formik.setFieldValue('isFloatingCard', floatingCardProp);
    formik.setFieldValue('isTextOnly', isTextOnlyProp);
    formik.setFieldValue('isPadded', isPaddedProp);
    formik.setFieldValue('alignment', alignmentProp);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock cols={4}>
            <CustomBlock
              hasNoContainer
              className="content-container--card-style--with-shadow"
            >
              <ContentHeader
                title="Components"
                headerSize="lg"
              />

              <CustomBlock className="selectable-preview-container">
                {
                  components.map((item) => (
                    <SelectablePreview
                      key={item.id}
                      title={item.title}
                      imageSource={item.image}
                      isSelected={item.type === formik.values.type}
                      titleColor={item.type === formik.values.type ? 'success--clr' : 'mid-grey--clr'}
                      onClick={() => {
                        formik.setFieldValue('type', item.type);
                        showOrHideFields(item.type);
                      }}
                    />
                  ))
                }
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>

          <ContentBlock cols={8}>
            <FormBlock onSubmit={formik.handleSubmit}>

              {/* BASIC INFORMATION */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={formik.values.id === 0 ? 'New Content Section' : 'Content Section Details'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="admin-font fw-800 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Content Section Name"
                    placeholder="e.g. Call-To-Action Section"
                    isRequired
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock className="mt-15">
                  <CheckboxBlock
                    label="Is Content Section Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>

              {
                formik.values.type !== constants.CONTENT_SECTION.ContentSectionCustom
                && (
                  <>
                    {/* CONTENT DETAILS */}
                    <Section
                      hasNoContainer
                      className="content-container--card-style--with-shadow mt-30"
                    >
                      <ContentBlock>
                        <ContentHeader
                          title="Content Details"
                          headerSize="md"
                          className="admin-font fw-800 secondary--clr pb-15"
                        />
                      </ContentBlock>
                      <ContentBlock cols={8}>
                        <InputBlock
                          label="Title"
                          placeholder="e.g. Content Title"
                          errorMessage={formik.errors.title}
                          inputState={`${helper.getInputClasses(formik, 'title')}`}
                          {...formik.getFieldProps('title')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={4}>
                        <SelectBlock
                          label="Alignment"
                          options={alignmentOptions}
                          value={alignment}
                          errorMessage={formik.errors.type}
                          inputState={
                            (
                              formik.values.type === constants.CONTENT_SECTION.ContentSectionTypeDefault
                              || formik.values.type === constants.CONTENT_SECTION.ContentSectionTypeReversed)
                            && 'disabled'
                          }
                          onChange={(opt) => {
                            setAlignment(opt);
                            formik.setFieldValue('alignment', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock>
                        <TextAreaBlock
                          label="Content Details"
                          placeholder="e.g. We provide top-rated construction services from home renovations to custom construction. Our team is dedicated to delivering construction services that are unmatched and guaranteed."
                          errorMessage={formik.errors.description}
                          inputState={`${helper.getInputClasses(formik, 'description')}`}
                          {...formik.getFieldProps('description')}
                        />
                      </ContentBlock>

                      {
                        formik.values.type !== constants.CONTENT_SECTION.ContentSectionTypeTextOnly
                        && (
                          <>
                            <Section hasNoContainer className="mt-30">
                              <ContentBlock>
                                <ContentHeader
                                  title="Content Image"
                                  subtitle="Upload an image for the content section"
                                  headerSize="md"
                                  subtitleSize="md"
                                  className="admin-font fw-800 secondary--clr pb-15"
                                />
                              </ContentBlock>
                            </Section>
                            <ContentBlock>
                              {
                                formik.values.id === 0
                                  ? (
                                    <ContentBlock className="mt-15">
                                      <FileUploadBlock onupdatefiles={setFile} />
                                    </ContentBlock>
                                  )
                                  : (
                                    <>
                                      <CustomBlock className="flex-center pt-15 pb-30">
                                        <Image
                                          source={`${process.env.REACT_APP_API_URL}Attachments/${formik.values.imagePath}`}
                                          className="pl-10 pr-10"
                                        />
                                      </CustomBlock>
                                      <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                                    </>
                                  )
                              }
                            </ContentBlock>
                          </>
                        )
                      }

                    </Section>

                    {/* ACTIONS */}
                    <Section
                      hasNoContainer
                      className="content-container--card-style--with-shadow mt-30 mb-120"
                    >
                      <ContentBlock>
                        <ContentHeader
                          title="Actions"
                          headerSize="md"
                          className="admin-font fw-800 secondary--clr pb-15"
                        />
                      </ContentBlock>
                      <ContentBlock cols={3}>
                        <InputBlock
                          label="Primary Action Text"
                          placeholder="e.g. Learn More"
                          errorMessage={formik.errors.primaryButtonText}
                          inputState={`${helper.getInputClasses(formik, 'primaryButtonText')}`}
                          {...formik.getFieldProps('primaryButtonText')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <SelectBlock
                          label="Primary Action Type"
                          options={linkTypes}
                          isClearable
                          inputState={!formik.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'primaryButtonActionType')}`}
                          errorMessage={formik.errors.primaryButtonActionType}
                          {...formik.getFieldProps('primaryButtonActionType')}
                          onMenuOpen={() => formik.setFieldTouched('primaryButtonActionType', true)}
                          value={primaryActionType}
                          onChange={(opt) => {
                            opt = opt === null ? [] : opt;
                            setPrimaryActionType(opt);
                            formik.setFieldValue('primaryButtonActionType', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock cols={6}>
                        <InputBlock
                          label="Primary Action Link"
                          placeholder=""
                          errorMessage={formik.errors.primaryButtonLink}
                          inputState={!formik.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'primaryButtonLink')}`}
                          {...formik.getFieldProps('primaryButtonLink')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <InputBlock
                          label="Secondary Action Text"
                          placeholder="e.g. Contact Us"
                          errorMessage={formik.errors.secondaryButtonText}
                          inputState={`${helper.getInputClasses(formik, 'secondaryButtonText')}`}
                          {...formik.getFieldProps('secondaryButtonText')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <SelectBlock
                          label="Secondary Action Type"
                          options={linkTypes}
                          inputState={!formik.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'secondaryButtonActionType')}`}
                          errorMessage={formik.errors.secondaryButtonActionType}
                          {...formik.getFieldProps('secondaryButtonActionType')}
                          onMenuOpen={() => formik.setFieldTouched('secondaryButtonActionType', true)}
                          value={secondaryActionType}
                          onChange={(opt) => {
                            opt = opt === null ? [] : opt;
                            setSecondaryActionType(opt);
                            formik.setFieldValue('secondaryButtonActionType', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock cols={6}>
                        <InputBlock
                          label="Secondary Action Link"
                          placeholder=""
                          errorMessage={formik.errors.secondaryButtonLink}
                          inputState={!formik.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'secondaryButtonLink')}`}
                          {...formik.getFieldProps('secondaryButtonLink')}
                        />
                      </ContentBlock>
                    </Section>

                  </>
                )
              }

              {
                formik.values.type === constants.CONTENT_SECTION.ContentSectionCustom
                && (
                  <Section
                    hasNoContainer
                    className="content-container--card-style--with-shadow mt-30 mb-140"
                  >
                    <ContentBlock>
                      <ContentHeader
                        title="Text Editor"
                        headerSize="md"
                        className="admin-font fw-800 secondary--clr pb-15"
                      />
                    </ContentBlock>
                    <div
                      ref={quillContainerRef}
                      className="quill-container"
                    >
                      <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={formik.values.rawHTML || ''}
                        onChange={(val) => {
                          formik.setFieldValue('rawHTML', val);
                        }}
                        modules={modules}
                        formats={format}
                      />
                    </div>
                  </Section>
                )
              }

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={formik.values.id === 0 ? 'Create' : 'Save Changes'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/content-sections"
                optionalActionText="Back to Listing"
                optionalActionOnClick={() => {
                  history.goBack();
                }}
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(ContentSectionManagementPage);