// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
import { IoClose } from 'react-icons/io5';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
import Image from './Image';
// ASSETS
// import Logo from '../assets/img/logo.svg';
import Icon from '../assets/img/icon.svg';

const Drawer = (props) => {
  const {
    isOpen,
    backgroundColor,
    hasOverlay,
    hasShadow,
    headerLogo,
    mobileLogo,
    navigationItems,
    footerItems,
    to,
    onClick,
    closeActionOnClick,
    willCloseOnMaskPress
  } = props;

  return (
    <>
      {
        (isOpen && hasOverlay)
        && (
          <Overlay
            onClick={willCloseOnMaskPress && onClick}
            overlayDarkness="dark"
          />
        )
      }
      <CustomBlock className={`drawer ${backgroundColor} ${isOpen && 'open'} ${hasShadow && 'with-shadow'}`}>
        <CustomBlock className="header">
          <CustomBlock className="logo-block">
            {
              headerLogo
              && (
                <>
                  <Image
                    to={to}
                    onClick={onClick}
                    // source={headerLogo}
                    // source={`${process.env.REACT_APP_API_URL}Attachments/${headerLogo}`}
                    source={Icon}
                    className="desktop-logo"
                  />

                  <Image
                    to={to}
                    onClick={onClick}
                    // source={mobileLogo || headerLogo}
                    source={`${process.env.REACT_APP_API_URL}Attachments/${mobileLogo || headerLogo}`}
                    className="mobile-logo"
                  />
                </>
              )
            }

            <button
              className="close-action"
              onClick={closeActionOnClick}
            >
              <IoClose className="white--clr" size={35} />
            </button>
          </CustomBlock>

          <CustomBlock className="nav-block">
            {navigationItems}
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="footer">
          {footerItems}
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

Drawer.propTypes = {
  // MAIN PROPS
  backgroundColor: PropTypes.string,
  navigationItems: PropTypes.any,
  footerItems: PropTypes.any,

  // IMAGE PROPS
  headerLogo: PropTypes.string.isRequired,
  mobileLogo: PropTypes.string.isRequired,

  // FUNCTION PROPS
  onClick: PropTypes.func,
  closeActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  isOpen: PropTypes.bool,
  hasShadow: PropTypes.bool,
  willCloseOnMaskPress: PropTypes.bool,
};

Drawer.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'primary--sbg',
  navigationItems: null,
  footerItems: null,

  // FUNCTION PROPS
  onClick: () => { },
  closeActionOnClick: () => { },

  // BOOLEAN PROPS
  isOpen: false,
  hasShadow: false,
  willCloseOnMaskPress: false,
};

export default Drawer;